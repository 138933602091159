(($) => {

  // Scroll animation speed
  const speed = 900

  // Cashing objects
  const $root = $('html, body');
  const $scroll = $('.scroll-down');
  const $homeSection = $('.home-section-1');

  $scroll.on('click', function(event) {
  	event.preventDefault();
  	$homeSection.length ?
  		$root.animate({ scrollTop: $homeSection.offset().top }, speed) : console.log('none');
  });

})(jQuery)
